/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-07-02 14:07:17
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-07 15:28:04
 */
export const columns = [
  {
    title: '临时申请额度',
    dataIndex: 'temporaryQuota',
    key: 'temporaryQuota',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },

  {
    title: '审核状态',
    dataIndex: 'status',
    key: 'status',
    /*width:150,*/
    align: 'center',
    ellipsis: true,
    scopedSlots: {customRender: 'status'}
  },
  {
    title: '申请原因',
    dataIndex: 'applyForReasons',
    key: 'applyForReasons',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '剩余可分配',
    dataIndex: 'remainingNum',
    key: 'remainingNum',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '剩余可报备',
    dataIndex: 'useNum',
    key: 'useNum',
    /*width:150,*/
    align: 'center',
    // ellipsis: true,
    scopedSlots: {customRender: 'useNum'}
  },
  {
    title: '有效期',
    dataIndex: 'expireDate',
    key: 'expireDate',
    /*width:150,*/
    align: 'center',
    // ellipsis:true,
    scopedSlots: {customRender: 'expireDate'}
  },
  {
    title: '使用状态',
    dataIndex: 'quotaStatus',
    key: 'quotaStatus',
    /*width:150,*/
    align: 'center',
    // ellipsis:true,
    scopedSlots: {customRender: 'quotaStatus'}
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '创建人',
    dataIndex: 'createUser',
    key: 'createUser',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
]

export const storeColumns = [

  {
    title: '门店名称',
    dataIndex: 'storeName',
    key: 'storeName',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '临时分配额度',
    dataIndex: 'temporaryQuota',
    key: 'temporaryQuota',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  // {
  //   title: '审核状态',
  //   dataIndex: 'status',
  //   key: 'status',
  //   /*width:150,*/
  //   align: 'center',
  //   ellipsis: true,
  //   scopedSlots: {customRender: 'status'}
  // },
  // {
  //   title: '申请原因',
  //   dataIndex: 'applyForReasons',
  //   key: 'applyForReasons',
  //   /*width:150,*/
  //   align: 'center',
  //   ellipsis: true
  // },
  {
    title: '剩余可分配',
    dataIndex: 'remainingNum',
    key: 'remainingNum',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '剩余可报备',
    dataIndex: 'useNum',
    key: 'useNum',
    /*width:150,*/
    align: 'center',
    // ellipsis: true,
    scopedSlots: {customRender: 'useNum'}
  },
  {
    title: '有效期',
    dataIndex: 'startingTime',
    key: 'startingTime',
    /*width:150,*/
    align: 'center',
    // ellipsis:true,
    scopedSlots: {customRender: 'startingTime'}
  },
  {
    title: '使用状态',
    dataIndex: 'quotaStatus',
    key: 'quotaStatus',
    /*width:150,*/
    align: 'center',
    // ellipsis:true,
    scopedSlots: {customRender: 'quotaStatus'}
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '创建人',
    dataIndex: 'createUser',
    key: 'createUser',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
]

export const staffColumns = [

  {
    title: '门店名称',
    dataIndex: 'storeName',
    key: 'storeName',
    width:150,
    align: 'center',
    ellipsis: true
  },
  {
    title: '员工名称',
    dataIndex: 'managerName',
    key: 'managerName',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '临时分配额度',
    dataIndex: 'actualQuota',
    key: 'actualQuota',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  // {
  //   title: '临时申请额度',
  //   dataIndex: 'temporaryQuota',
  //   key: 'temporaryQuota',
  //   /*width:150,*/
  //   align: 'center',
  //   ellipsis: true
  // },
  // {
  //   title: '审核状态',
  //   dataIndex: 'status',
  //   key: 'status',
  //   /*width:150,*/
  //   align: 'center',
  //   ellipsis: true,
  //   scopedSlots: {customRender: 'status'}
  // },
  // {
  //   title: '申请原因',
  //   dataIndex: 'applyForReasons',
  //   key: 'applyForReasons',
  //   /*width:150,*/
  //   align: 'center',
  //   ellipsis: true
  // },
  {
    title: '剩余可报备',
    dataIndex: 'remainingNum',
    key: 'remainingNum',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '有效期',
    dataIndex: 'expireDate',
    key: 'expireDate',
    width:150,
    align: 'center',
    // ellipsis:true,
    scopedSlots: {customRender: 'expireDate'}
  },
  {
    title: '使用状态',
    dataIndex: 'quotaStatus',
    key: 'quotaStatus',
    /*width:150,*/
    align: 'center',
    // ellipsis:true,
    scopedSlots: {customRender: 'quotaStatus'}
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '创建人',
    dataIndex: 'createUser',
    key: 'createUser',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  // {
  //   title: '汇总状态',
  //   dataIndex: 'summary',
  //   key: 'summary',
  //   /*width:150,*/
  //   align: 'center',
  //   ellipsis: true,
  //   scopedSlots: {customRender: 'summary'},
  // },
]